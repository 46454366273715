import React, { useState } from 'react';
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import ArticleCard from '../components/ArticleCard'

import Search from '../components/Search';
import Seo from '../components/seo'

import BlackLogo from "../images/logos/black_logo.svg"
import SleepyJatin from "../images/sleepy_jatin.svg"
import AuthorScribble from "../images/blog_author.svg"

import CircleIcon from "../images/circle_icon.svg"
import TriangleIcon from "../images/triangle_icon.svg"
import SquareIcon from "../images/square_icon.svg"

const iconArray = [TriangleIcon, SquareIcon, CircleIcon]

export default function HomePage(props) {
  const articles = get(props, 'data.allContentfulBlog.nodes')
  const topics = get(props, 'data.allContentfulTopic.nodes')

  const [selectedTitle, setSelectedTitle] = useState(null)
  const [selectedTopics, setSelectedTopics] = useState([])

  console.log(props.location.search)
  console.log(selectedTopics)

  let filteredArticles = articles?.filter((article, i) => (!selectedTitle || article.title.toLowerCase().search(selectedTitle) !== -1) && (selectedTopics.length === 0 || selectedTopics.includes(article.topic)))

  return (
    <Layout location={props.location}>
      <Seo
        title='Fundamentals First'
        description='A technical technology-related blog by Jatin Mehta.'
      />
      <section className='landing-hero'>
        <img src={BlackLogo} alt="hero logo" className='landing-hero-logo' />
        <div className='author-scribble-wrapper'>
          <img src={AuthorScribble} className='author-scribble-img' alt="Blog by Jatin Mehta" />
        </div>
      </section>
      <section className='search-wrapper'>
        <Search topics={topics} setSelectedTitle={setSelectedTitle} selectedTitle={selectedTitle} setSelectedTopics={setSelectedTopics} selectedTopics={selectedTopics} />
      </section>
      <section style={sectionStyle}>
        <div>
          {(filteredArticles && filteredArticles.length > 0) ? filteredArticles.map((article, i) => <ArticleCard title={article.title} slug={article.slug} topic={article.topic} date={article.publishDate} read_time={article.readTime} icon={iconArray[i % iconArray.length]} />) : <h2 style={{ textAlign: 'center' }}>No Articles Found</h2>}
        </div>
      </section>
      <div className='more-articles-wrapper'>
        <div className='more-articles-img'>
          <img src={SleepyJatin} alt="Sleepy Jatin" />
        </div>
        <h2 className='more-articles'>More articles after a short nap.</h2>
      </div>
    </Layout >
  )
}




const sectionStyle = {
  padding: '48px 0'
}


export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlog(sort: {fields: [publishDate], order: DESC}) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        readTime
        topic
      }
    }
    allContentfulTopic {
      nodes {
        title
      }
    }
  }
`
